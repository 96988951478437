//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Indicator from "./Indicator";
export default {
  props: {
    title: {
      required: true
    },
    image: {
      required: true
    },
    text1: {
      required: true
    },
    text2: {
      required: true
    }
  },
  components: {
    Indicator
  }
};